import React from 'react';

import { Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';
import { SCOPE_TYPE } from '../../../../services/credentialFactory';
import SignInButton from '../components/SignInButton';
import { SHOW_OTHER_PROVIDERS } from '../../../../featureFlags';
import { useTranslation } from 'react-i18next';
import { LandingPageFlavorEnum } from './welcomeFlavors.types';

export type WelcomeSignInFooterProps = {
  landingPageFlavor: LandingPageFlavorEnum;
  next: () => void;
};

function WelcomeSignInFooter(props: WelcomeSignInFooterProps) {
  const { landingPageFlavor, next } = props;

  const useDirectLogin = false;
  const { t } = useTranslation();
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <SignInButton
        useGmailIcon={useDirectLogin}
        buttonText={t(useDirectLogin ? 'connectGmail' : 'signInWithGoogle')}
        scopeType={useDirectLogin ? SCOPE_TYPE.ALL_SCOPES : SCOPE_TYPE.USER_INFO}
      ></SignInButton>
      {SHOW_OTHER_PROVIDERS && (
        <Button
          variant='text'
          size='large'
          color='black'
          sx={{
            margin: aboveSmall ? '1rem' : isExtraSmall ? '0.5rem' : '0.75rem',
            marginTop: 0,
            borderRadius: '2px',
          }}
          onClick={async () => {
            AnalyticsService.track(EventName.CLICK_USE_ANOTHER_PROVIDER, { landingPageFlavor });
            next();
          }}
        >
          {t('useAnotherProvider')}
        </Button>
      )}
      <Typography
        variant='body2'
        sx={{
          margin: aboveSmall ? '1rem 2.5rem 2rem 2.5rem' : '1rem 2rem 1rem 2rem ',
          fontSize: isExtraSmall ? '0.75rem' : aboveSmall ? '0.85rem' : '0.75rem',
        }}
      >
        {t('signUpAgree')}
        <a className='link' href='https://www.trimbox.io/privacy'>
          {t('privacyPolicy')}
        </a>
      </Typography>
    </>
  );
}

export default WelcomeSignInFooter;
