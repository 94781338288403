import React, { useEffect, useState } from 'react';

import Welcome from './Welcome/Welcome';
import { useLiveQuery } from 'dexie-react-hooks';
import { useGetMailboxData } from '../../../app/useGetMailboxData';
import Mailbox, { getCurrentMailboxQuery } from '../../../model/mailbox';
import { LandingPageFlavorEnum } from './Welcome/welcomeFlavors.types';
import { useNavigate } from 'react-router-dom';
import EnterEmail from './EnterEmail/EnterEmail';
import WelcomePhotoCleaner from './Welcome/WelcomePhotoCleaner';

export type NativeAppFunnelFlowProps = {
  landingPageFlavor: LandingPageFlavorEnum;
};

const USE_PHOTO_CLEANING_FEATURES = true;

function NativeAppFunnelFlow(props: NativeAppFunnelFlowProps) {
  const { landingPageFlavor } = props;

  const mailbox: Mailbox | undefined | null = useLiveQuery(getCurrentMailboxQuery);
  const { hasTokenAndUserInfoScopes } = useGetMailboxData(mailbox);

  const [onboardingStep, setOnboardingStep] = useState<'WELCOME' | 'EMAIL'>('WELCOME');

  const navigate = useNavigate();

  useEffect(() => {
    if (hasTokenAndUserInfoScopes) {
      navigate('/');
    }
  }, [hasTokenAndUserInfoScopes]);

  if (onboardingStep === 'WELCOME') {
    if (USE_PHOTO_CLEANING_FEATURES) {
      return (
        <WelcomePhotoCleaner
          next={() => {
            setOnboardingStep('EMAIL');
          }}
          landingPageFlavor={landingPageFlavor}
          nativeAppFunnelMode={true}
        ></WelcomePhotoCleaner>
      );
    } else {
      return (
        <Welcome
          next={() => {
            setOnboardingStep('EMAIL');
          }}
          landingPageFlavor={landingPageFlavor}
          nativeAppFunnelMode={true}
        ></Welcome>
      );
    }
  }

  return (
    <EnterEmail
      next={(email) => {
        navigate(`/upgrade?email=${email}`);
      }}
    ></EnterEmail>
  );
}

export default NativeAppFunnelFlow;
