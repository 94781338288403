import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { WHITE_HEX, LIGHTER_GRAY_HEX, RED_COLOR_HEX } from '../../theme';

interface BadgeIconProps {
  icon: string;
  badgeCount: number;
  alt?: string;
}

const BadgeIcon: React.FC<BadgeIconProps> = ({ icon, badgeCount, alt }) => {
  const theme = useTheme();
  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        component='img'
        src={icon}
        alt={alt}
        sx={{
          width: aboveMedium ? 108 : 96,
          height: aboveMedium ? 108 : 96,
          //   borderRadius: 2,
          //   border: `1px solid ${LIGHTER_GRAY_HEX}`,
          //   boxShadow: '0px 4px 10px rgba(0,0,0,0.1)',
          //   padding: 2,
          //   backgroundColor: WHITE_HEX,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: -20,
          right: -20,
          backgroundColor: RED_COLOR_HEX,
          width: aboveMedium ? 46 : 36,
          height: aboveMedium ? 46 : 36,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            color: WHITE_HEX,
            fontSize: aboveMedium ? '1rem' : '0.875rem',
            fontWeight: 'bold',
          }}
        >
          {badgeCount}
        </Typography>
      </Box>
    </Box>
  );
};

export default BadgeIcon;
