import React from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';

interface LargeActionButtonProps {
  onClick: () => void;
  children: React.ReactNode;
}

const LargeActionButton: React.FC<LargeActionButtonProps> = ({ onClick, children }) => {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Button
      variant='contained'
      size='large'
      onClick={onClick}
      sx={{
        width: '90%',
        maxWidth: 400,
        height: 60,
        borderRadius: 2,
        fontSize: aboveSmall ? '1.5rem' : '1.25rem',
        fontWeight: 'bold',
        marginTop: 'auto',
        marginBottom: 2,
        textTransform: 'none',
      }}
    >
      {children}
    </Button>
  );
};

export default LargeActionButton;
