import React, { useCallback, useEffect } from 'react';

import { OptionsObject, useSnackbar } from 'notistack';
import {
  openStudentPromoDialog,
  setFailedGmailConnection,
  setWebViewInfo,
} from '../../../../features/ui/uiSlice';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';
import { MissingScopesError } from '../../../../services/mailboxConnector.errors';
import Mailbox from '../../../../model/mailbox';
import { SCOPE_TYPE } from '../../../../services/credentialFactory';
import MailboxConnector from '../../../../services/mailboxConnector';
import { WebViewDetector } from '../../../../services/webViewDetector/webViewDetector';
import { useAppDispatch } from '../../../../app/hooks';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GoogleSignInButton from '../../../GoogleSignInButton/GoogleSignInButton';

export type GetStartedButtonProps = {
  buttonText: string;
  scopeType: SCOPE_TYPE;
  useGmailIcon: boolean;
};

function SignInButton(props: GetStartedButtonProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const authCode = searchParams.get('code');
  const authError = searchParams.get('error');

  const { buttonText, scopeType, useGmailIcon } = props;

  useEffect(() => {
    if (authError) {
      AnalyticsService.trackError(EventName.LOGIN_FAILED, authError);

      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.delete('error');
        return newParams;
      });
      dispatch(setFailedGmailConnection(true));
      return;
    }

    if (!authCode) {
      return;
    }

    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.delete('code');
      newParams.delete('scope');
      newParams.delete('authuser');
      newParams.delete('prompt');
      newParams.delete('hd');
      return newParams;
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const g = google;

    const finishAuthentication = async () => {
      try {
        const mailbox = await MailboxConnector.create(g).finishConnection(
          authCode,
          props.scopeType
        );

        if (props.scopeType !== SCOPE_TYPE.USER_INFO) {
          AnalyticsService.track(EventName.LOGIN_SUCCESSFUL);
          dispatch(setFailedGmailConnection(false));

          if (mailbox?.email_address?.endsWith('.edu')) {
            dispatch(openStudentPromoDialog());
          }
        }
      } catch (e) {
        AnalyticsService.trackError(EventName.LOGIN_FAILED, e);

        if (e instanceof MissingScopesError) {
          dispatch(setFailedGmailConnection(true));
          return;
        }

        if ((e as Error).message === 'Popup window closed') {
          dispatch(setFailedGmailConnection(true));
          return;
        }

        if ((e as Error).message?.startsWith("Failed to execute 'text' on 'Response'")) {
          dispatch(setFailedGmailConnection(true));
          return;
        }

        const snackbarOptions: OptionsObject = {
          variant: 'error',
          preventDuplicate: true,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
          persist: false,
        };

        const errorMessage = t('failedToConnectGmail');
        enqueueSnackbar(errorMessage, snackbarOptions);
      }
    };

    // call the function
    finishAuthentication()
      // make sure to catch any error
      .catch(console.error);
  }, [authCode, authError]);

  const onSignIn = useCallback(async () => {
    if (scopeType === SCOPE_TYPE.GMAIL_ACCESS) {
      AnalyticsService.track(EventName.CLICK_CONNECT_MAILBOX);
    } else {
      AnalyticsService.track(EventName.CLICK_LOGIN);
    }

    const webViewInfo = WebViewDetector.checkForWebView();
    if (webViewInfo?.isInApp) {
      dispatch(setWebViewInfo(webViewInfo));
      return;
    }

    if (process.env.REACT_APP_TRIMBOX_ENV === 'pentest') {
      // await Mailbox.delete();
      await Mailbox.createZapMailbox();
      return;
    }

    if (scopeType !== SCOPE_TYPE.GMAIL_ACCESS) {
      AnalyticsService.track(EventName.LOGIN_START);
    }
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const g = google;

      MailboxConnector.create(g).startConnection(scopeType);
    } catch (e: unknown) {
      if (props.scopeType === SCOPE_TYPE.GMAIL_ACCESS) {
        AnalyticsService.trackError(EventName.CONNECT_MAILBOX_FAILED, e);
      } else {
        AnalyticsService.trackError(EventName.LOGIN_FAILED, e);
      }
      showErrorToast(e, scopeType);
    }
  }, [scopeType]);

  return (
    <GoogleSignInButton buttonText={buttonText} onSignIn={onSignIn} useGmailIcon={useGmailIcon} />
  );

  function showErrorToast(e: unknown, scopeType: SCOPE_TYPE) {
    if (scopeType !== SCOPE_TYPE.USER_INFO) {
      if (e instanceof MissingScopesError) {
        dispatch(setFailedGmailConnection(true));
        return;
      }

      if ((e as Error).message === 'Popup window closed') {
        dispatch(setFailedGmailConnection(true));
        return;
      }

      if ((e as Error).message?.startsWith("Failed to execute 'text' on 'Response'")) {
        dispatch(setFailedGmailConnection(true));
        return;
      }
    }

    const snackbarOptions: OptionsObject = {
      variant: 'error',
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      persist: false,
    };

    const errorMessage =
      scopeType === SCOPE_TYPE.USER_INFO ? t('failedToSignIn') : t('failedToConnectGmail');

    enqueueSnackbar(errorMessage, snackbarOptions);
  }
}
export default SignInButton;
