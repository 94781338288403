import { SubscriptionProperties } from '../../../../../model/subscription';
import { LandingPageFlavorEnum } from '../welcomeFlavors.types';

export const MOCKED_LIST_PROPERTIES: SubscriptionProperties[] = [
  {
    email_address: 'deals@kayak.com',
    display_name: 'KAYAK',
    message_count: 312,
  },
  {
    email_address: 'sales@macys.com',
    display_name: "Macy's Sale",
    message_count: 158,
  },
  {
    email_address: 'promotions@bestbuy.com',
    display_name: 'Best Buy',
    message_count: 45,
  },
  {
    email_address: 'notifications@facebook.com',
    display_name: 'Facebook',
    message_count: 47,
  },
  {
    email_address: 'hello@siriusxm.com',
    display_name: 'SiriusXM',
    message_count: 38,
  },
];

export function getButtonCommandText(flavor: LandingPageFlavorEnum, completed: boolean): string {
  const buttonFlavor = landingPageButtonFlavors[flavor];
  const buttonTexts = landingPageButtonTexts[buttonFlavor];
  if (completed) {
    return buttonTexts.completedButtonText;
  }
  return buttonTexts.positiveButtonText;
}

export enum ExampleEmailListActionButtonFlavorEnum {
  UNSUBSCRIBE = 'unsubscribe',
  DELETE = 'delete',
  BLOCK = 'block',
  // TODO
  MARK_AS_READ = 'mark_as_read',
}

// Mapping of each flavor to its email action button flavor
const landingPageButtonFlavors: Record<
  LandingPageFlavorEnum,
  ExampleEmailListActionButtonFlavorEnum
> = {
  [LandingPageFlavorEnum.CLEAN_EMAIL]: ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE,
  [LandingPageFlavorEnum.STOP_SPAM]: ExampleEmailListActionButtonFlavorEnum.BLOCK,
  [LandingPageFlavorEnum.UNSUBSCRIBE]: ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE,
  [LandingPageFlavorEnum.DELETE]: ExampleEmailListActionButtonFlavorEnum.DELETE,
  [LandingPageFlavorEnum.MARK_AS_READ]: ExampleEmailListActionButtonFlavorEnum.MARK_AS_READ,
  [LandingPageFlavorEnum.GMAIL_CLEANER]: ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE,
  [LandingPageFlavorEnum.HOW_TO_UNSUBSCRIBE]: ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE,
  [LandingPageFlavorEnum.FREE_CLEANER]: ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE,
  [LandingPageFlavorEnum.BLOCK_SPAM]: ExampleEmailListActionButtonFlavorEnum.BLOCK,
  [LandingPageFlavorEnum.HOW_TO_GMAIL_DELETE]: ExampleEmailListActionButtonFlavorEnum.DELETE,
  [LandingPageFlavorEnum.GMAIL_DELETE]: ExampleEmailListActionButtonFlavorEnum.DELETE,
  [LandingPageFlavorEnum.UNSUBSCRIBE_GMAIL]: ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE,
  [LandingPageFlavorEnum.UNSUBSCRIBE_MAILING_LIST]:
    ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE,
  [LandingPageFlavorEnum.UNSUBSCRIBE_APP]: ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE,
  [LandingPageFlavorEnum.PHOTO_CLEANER]: ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE,
};

// Mapping of each flavor to its email action button flavor
const landingPageButtonTexts: Record<
  ExampleEmailListActionButtonFlavorEnum,
  { completedButtonText: string; positiveButtonText: string }
> = {
  [ExampleEmailListActionButtonFlavorEnum.UNSUBSCRIBE]: {
    completedButtonText: 'unsubscribed',
    positiveButtonText: 'unsubscribe',
  },
  [ExampleEmailListActionButtonFlavorEnum.DELETE]: {
    completedButtonText: 'deleted',
    positiveButtonText: 'delete',
  },
  [ExampleEmailListActionButtonFlavorEnum.BLOCK]: {
    completedButtonText: 'blocked',
    positiveButtonText: 'block',
  },
  [ExampleEmailListActionButtonFlavorEnum.MARK_AS_READ]: {
    completedButtonText: 'isRead',
    positiveButtonText: 'markAsRead',
  },
};
