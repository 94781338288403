import React from 'react';

import { useGetCurrentPlanInterval } from '../../app/useGetCurrentPlanInterval';
import { useGetOffering } from '../../app/useGetOffering';
import { useLoadPricingPage } from '../../app/useLoadPricingPage';
import {
  PlanTypePresentable,
  CurrencyConfig,
  PromoCode,
} from '../../features/offering/offeringSlice.types';
import { getCurrentMailboxQuery } from '../../model/mailbox';
import PlanOption from './PlanOption';
import { useMediaQuery, Stack } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import theme from '../../theme';

export type HorizontalPlansContainerProps = {
  userEmailFromURL?: string;
  nativeAppFunnelMode?: boolean;
  onCheckout: (
    plan: PlanTypePresentable,
    currency: CurrencyConfig,
    promoCode: PromoCode | undefined
  ) => Promise<void>;
};

export default function HorizontalPlansContainer(props: HorizontalPlansContainerProps) {
  const { onCheckout } = props;

  const mailbox = useLiveQuery(getCurrentMailboxQuery);

  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));

  const { promoCode } = useLoadPricingPage(mailbox);

  const { plans, currency } = useGetOffering({
    includeFree: aboveMedium,
    sortByAscending: aboveMedium,
    mailbox,
  });

  const currentPlanIntervals = useGetCurrentPlanInterval(mailbox);

  if (!currency) {
    return null;
  }

  return (
    <Stack
      spacing={5}
      justifyContent='center'
      direction={aboveMedium ? 'row' : 'column'}
      alignItems={aboveMedium ? 'start' : 'center'}
    >
      {plans?.map((plan) => (
        <PlanOption
          key={plan.interval}
          plan={plan}
          currentPlanIntervals={currentPlanIntervals}
          onCheckoutClick={async () => {
            await onCheckout(plan, currency, promoCode);
          }}
          currency={currency}
        />
      ))}
    </Stack>
  );
}
