import React from 'react';

import PlanSelection from './PlanSelection/PlanSelection';
import Mailbox from '../../../model/mailbox';
import { Button, useMediaQuery } from '@mui/material';
import {
  CurrencyConfig,
  PlanTypePresentable,
  PromoCode,
} from '../../../features/offering/offeringSlice.types';
import { t } from 'i18next';
import theme, { BLACK_HEX } from '../../../theme';

export type PlansContainerProps = {
  onAlreadyPaid?: () => void;
  onCheckout: (
    plan: PlanTypePresentable,
    currency: CurrencyConfig,
    promoCode: PromoCode | undefined
  ) => Promise<void>;
  mailbox: Mailbox | null | undefined;
  buttonText?: string;
};

function PlansContainer(props: PlansContainerProps) {
  const { onAlreadyPaid, mailbox, buttonText, onCheckout } = props;
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <PlanSelection mailbox={mailbox} buttonText={buttonText} onCheckout={onCheckout} />
      {onAlreadyPaid && (
        <Button
          variant='text'
          size='small'
          onClick={() => {
            onAlreadyPaid();
          }}
          sx={{
            margin: 'auto',
            marginTop: '1rem',
            fontSize: isExtraSmall ? '0.75rem' : '0.85rem',
            color: BLACK_HEX,
            width: 'fit-content',
          }}
        >
          {t('alreadyPaid')}
        </Button>
      )}
    </>
  );
}

export default PlansContainer;
