import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { useTheme, useMediaQuery, Stack, Button } from '@mui/material';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';
import MockSignInButton from '../../../MockSignInButton';
import yahoo from '../../../../assets/yahoo.png';
import aol from '../../../../assets/aol.png';
import outlook from '../../../../assets/outlook.png';
import banner_image from '../../../../assets/banner_image.svg';
import ConnectGmailPromptBadge, {
  ConnectGmailPromptBadgeIcon,
  ConnectGmailPromptBadgeProps,
} from '../../../ConnectGmailPrompt/ConnectGmailPromptBadge';
import { useTranslation } from 'react-i18next';
import PageHeaderInfo from '../components/PageHeaderInfo';

function SelectProvider() {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [selectedProvider, setSelectedProvider] = useState<string>();

  const { t } = useTranslation();

  const badge1Props: ConnectGmailPromptBadgeProps = {
    icon: ConnectGmailPromptBadgeIcon.VERIFIED,
    title: t('certifiedTitle'),
    description: t('certifiedSubtitle'),
  };

  const badge2Props: ConnectGmailPromptBadgeProps = {
    icon: ConnectGmailPromptBadgeIcon.TRANSPARENT,
    title: t('dataTitle'),
    description: t('dataSubtitle'),
  };

  return (
    <Stack
      direction='column'
      alignItems='center'
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box
        margin={2}
        component='img'
        src={banner_image}
        sx={{
          height: aboveSmall ? 40 : isExtraSmall ? 24 : 32,
        }}
      />
      <Stack
        flexGrow={1}
        alignItems='center'
        sx={{
          margin: aboveSmall ? '0rem' : '1rem',
        }}
      >
        {selectedProvider ? (
          <>
            <PageHeaderInfo
              headerText={t('providerNotSupported', { provider: selectedProvider })}
              subHeaderText={t('checkLater')}
              noTopMargin
            />
            <Box>
              <Button
                variant='contained'
                size='large'
                color='primary'
                sx={{ marginTop: '1rem' }}
                onClick={async () => {
                  setSelectedProvider(undefined);
                }}
              >
                {t('tryAnotherProvider')}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <PageHeaderInfo
              headerText={t('selectProvider')}
              subHeaderText={t('connectProviderUpsell')}
              noTopMargin
            />
            <Stack sx={{ marginTop: '0rem' }}>
              <MockSignInButton
                avatarString={yahoo}
                buttonText={'Yahoo Mail'}
                buttonColor={'yahooPurple'}
                onClick={async () => {
                  AnalyticsService.track(EventName.REQUEST_PROVIDER, { provider: 'yahoo' });
                  setSelectedProvider('Yahoo Mail');
                }}
              ></MockSignInButton>
              <MockSignInButton
                avatarString={outlook}
                buttonText={'Outlook'}
                buttonColor={'outlookBlue'}
                onClick={async () => {
                  AnalyticsService.track(EventName.REQUEST_PROVIDER, { provider: 'outlook' });
                  setSelectedProvider('Outlook');
                }}
              ></MockSignInButton>
              <MockSignInButton
                avatarString={aol}
                buttonText={'AOL Mail'}
                buttonColor={'aolBlack'}
                onClick={async () => {
                  AnalyticsService.track(EventName.REQUEST_PROVIDER, { provider: 'aol' });
                  setSelectedProvider('AOL Mail');
                }}
              ></MockSignInButton>
            </Stack>
          </>
        )}
      </Stack>
      {!selectedProvider && (
        <Box
          sx={{
            display: 'flex',
            ...(aboveSmall
              ? {
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-evenly',
                }
              : { padding: '0rem 1rem', flexDirection: 'column' }),
          }}
        >
          <ConnectGmailPromptBadge {...badge1Props} />
          <ConnectGmailPromptBadge {...badge2Props} />
        </Box>
      )}
    </Stack>
  );
}

export default SelectProvider;
