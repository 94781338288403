import React, { useEffect, useState } from 'react';

import { Collapse, List } from '@mui/material';
import { useTheme, useMediaQuery, Box } from '@mui/material';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import banner_image from '../../../../assets/banner_image.svg';
import WelcomeHeader from '../components/WelcomeHeader';
import {
  ExampleEmailListItemProps,
  getNumberOfExampleEmailLists,
  ExampleEmailListItem,
} from './ExampleEmailList/ExampleEmailListItem';
import { MOCKED_LIST_PROPERTIES } from './ExampleEmailList/ExampleEmailList.types';
import { LandingPageFlavorEnum } from './welcomeFlavors.types';
import WelcomeSignInFooter from './WelcomeSignInFooter';
import WelcomeNativeAppFunnelFooter from './WelcomeNativeAppFunnelFooter';

const CONTAINER_CLASS_NAME = 'ExampleEmailListContainer';

type ExampleEmailListItemsWithoutFlavor = Omit<ExampleEmailListItemProps, 'landingPageFlavor'>[];

export type WelcomeProps = {
  landingPageFlavor: LandingPageFlavorEnum;
  nativeAppFunnelMode: boolean;
  next: () => void;
};

function Welcome(props: WelcomeProps) {
  const { landingPageFlavor, next, nativeAppFunnelMode } = props;

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const [examples, setExamples] = useState<ExampleEmailListItemsWithoutFlavor>([]);
  const [exampleToUpdate, setExampleToUpdate] = useState<number>();

  useEffect(() => {
    if (nativeAppFunnelMode) {
      AnalyticsService.track(EventName.NATIVE_FUNNEL_START);
    } else {
      AnalyticsService.track(EventName.GET_STARTED_VISIT);
    }
  }, [nativeAppFunnelMode]);

  // After a short delay, render as many example lists as we can fit
  useEffect(() => {
    setTimeout(() => {
      const collection = document.getElementsByClassName(CONTAINER_CLASS_NAME);
      const container = collection.item(0);

      const numberOfExampleLists = container
        ? getNumberOfExampleEmailLists(container, aboveSmall, isExtraSmall)
        : 2;

      const exampleLists: ExampleEmailListItemsWithoutFlavor = MOCKED_LIST_PROPERTIES.slice(
        0,
        numberOfExampleLists
      ).map((exampleList) => {
        return {
          ...exampleList,
          currentState: undefined,
        };
      });

      setExamples(exampleLists);

      setTimeout(() => {
        setExampleToUpdate(0);
      }, 1000);
    }, 500);
  }, []);

  // After example lists are rendered, start clicking buttons
  useEffect(() => {
    if (exampleToUpdate === undefined) {
      return;
    }
    if (exampleToUpdate >= examples.length) {
      return;
    }

    setTimeout(() => {
      const updatedExamples = examples.map((example, index) => {
        if (index == exampleToUpdate) {
          example.currentState = exampleToUpdate == 1 ? 'kept' : 'unsubscribed';
        }
        return example;
      });
      setExamples(updatedExamples);
      setExampleToUpdate(exampleToUpdate + 1);
    }, 500);
  }, [exampleToUpdate]);

  return (
    <>
      <Box
        margin={2}
        marginBottom={0}
        component='img'
        src={banner_image}
        sx={{
          height: aboveSmall ? 36 : isExtraSmall ? 24 : 32,
        }}
      />
      <WelcomeHeader flavor={landingPageFlavor}></WelcomeHeader>
      <Box
        className={CONTAINER_CLASS_NAME}
        sx={{
          display: 'flex',
          flexGrow: 1,
          alignItems: 'center',
          width: '100%',
          ...(aboveSmall ? { maxWidth: '40rem' } : {}),
        }}
      >
        <List
          sx={{
            margin: aboveSmall ? '1rem 1rem 0rem 1rem' : '0.5rem 1rem 0rem 1rem',
            textAlign: 'end',
            padding: 0,
            width: '100%',
          }}
        >
          <TransitionGroup>
            {examples?.map((exampleList) => {
              return (
                <Collapse key={exampleList.email_address}>
                  <ExampleEmailListItem
                    {...exampleList}
                    landingPageFlavor={landingPageFlavor}
                  ></ExampleEmailListItem>
                </Collapse>
              );
            })}
          </TransitionGroup>
        </List>
      </Box>
      {nativeAppFunnelMode ? (
        <WelcomeNativeAppFunnelFooter next={next} />
      ) : (
        <WelcomeSignInFooter next={next} landingPageFlavor={landingPageFlavor} />
      )}
    </>
  );
}

export default Welcome;
