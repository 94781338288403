import React, { useEffect } from 'react';
import { Stack, Button, Typography, Box, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import { WebViewDetector } from '../../services/webViewDetector/webViewDetector';
import { APP_STORE_LINK, PLAY_STORE_LINK } from '../../constants';

export type InstallPageProps = {
  //
};

export default function InstallPage(props: InstallPageProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    const webViewInfo = WebViewDetector.checkForWebView();
    if (webViewInfo?.deviceOS === 'ios') {
      window.location.href = APP_STORE_LINK;
    } else if (webViewInfo?.deviceOS === 'android') {
      window.location.href = PLAY_STORE_LINK;
    }
  }, []);

  const BUTTON_WIDTH = 200;
  const BUTTON_HEIGHT = 60;

  // Set the CSS variable for vh like in IntroPage
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
  }, []);

  return (
    <Stack
      sx={{
        minHeight: 'calc(var(--vh) * 100)',
        background: 'linear-gradient(to bottom, #a1c4fd 0%, #c2e9fb 40%, #f9f9f9 70%)',
        padding: { xs: 2, sm: 4 },
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          width: '100%',
          maxWidth: 500,
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          borderRadius: 2,
          padding: { xs: 3, sm: 4 },
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Stack spacing={1} alignItems='center'>
          <Typography variant='h4' textAlign='center' sx={{ fontWeight: 600 }}>
            {t('UNSUBSCRIBE_APP_HEADER')}
          </Typography>
          <Typography variant='body1' textAlign='center' color='text.secondary'>
            {t('UNSUBSCRIBE_APP_SUBHEADER')}
          </Typography>
        </Stack>

        <Stack spacing={2} sx={{ width: '100%', alignItems: 'center' }}>
          <Box
            component='a'
            href={APP_STORE_LINK}
            target='_blank'
            rel='noopener noreferrer'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: BUTTON_WIDTH,
              height: BUTTON_HEIGHT,
              '&:hover': {
                opacity: 0.8,
              },
            }}
          >
            <img
              src='https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us'
              alt={t('visitAppStore') as string}
              style={{
                width: '90%',
                height: '90%',
                objectFit: 'contain',
              }}
            />
          </Box>

          <Box
            component='a'
            href={PLAY_STORE_LINK}
            target='_blank'
            rel='noopener noreferrer'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: BUTTON_WIDTH,
              height: BUTTON_HEIGHT,
              '&:hover': {
                opacity: 0.8,
              },
            }}
          >
            <img
              src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
              alt={t('useTheApp') as string}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                transform: 'scale(1.15)',
              }}
            />
          </Box>
        </Stack>

        <Stack
          spacing={1.5}
          sx={{
            width: '100%',
            alignItems: 'center',
            pt: 2,
          }}
        >
          <Divider sx={{ width: '100%', opacity: 0.7 }}>
            <Typography variant='body2' color='text.secondary' sx={{ px: 2 }}>
              {t('or')}
            </Typography>
          </Divider>

          <Button
            variant='text'
            onClick={() => navigate('/')}
            sx={{
              fontSize: '14px',
              color: theme.palette.primary.main,
              textTransform: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            {t('continue')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
