import React from 'react';

import { useTheme, useMediaQuery, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlansList from './PlansList';
import { selectSelectedPlanInterval } from '../../../../features/offering/offeringSlice';
import { useAppSelector } from '../../../../app/hooks';
import TotalDue from '../TotalDue';
import { useGetOffering } from '../../../../app/useGetOffering';
import Mailbox from '../../../../model/mailbox';
import { useHasGivenFeedback } from '../../../../app/useGetChurnFeedback';
import {
  PlanTypePresentable,
  CurrencyConfig,
  PromoCode,
} from '../../../../features/offering/offeringSlice.types';

export type PlanSelectionProps = {
  mailbox: Mailbox | null | undefined;
  buttonText?: string;
  onCheckout: (
    plan: PlanTypePresentable,
    currency: CurrencyConfig,
    promoCode: PromoCode | undefined
  ) => Promise<void>;
};

function PlanSelection(props: PlanSelectionProps) {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  const { mailbox, onCheckout, buttonText } = props;

  const selectedPlanIndex = useAppSelector(selectSelectedPlanInterval);
  const hasFeedbackDiscount = useHasGivenFeedback(mailbox);

  const { hasAnnualOption, discountPercentage, currency, selectedPlan, promoCode } = useGetOffering(
    { mailbox }
  );

  return (
    <>
      {hasFeedbackDiscount && (
        <Typography
          variant={isExtraSmall ? 'body2' : 'body1'}
          textAlign='center'
          margin={theme.spacing(1, 0)}
        >
          {t('additionalPercentOff', { percentage: discountPercentage * 100 })}
        </Typography>
      )}
      <PlansList hasFeedbackDiscount={hasFeedbackDiscount} mailbox={mailbox} />
      {selectedPlanIndex !== undefined && selectedPlanIndex !== null && hasAnnualOption && (
        <TotalDue mailbox={mailbox} />
      )}
      <Button
        variant='contained'
        size='large'
        disabled={selectedPlanIndex === undefined || selectedPlanIndex === null}
        onClick={() => {
          if (!selectedPlan || !currency) {
            console.error('No selected plan or currency');
            return;
          }
          onCheckout(selectedPlan, currency, promoCode);
        }}
        sx={{
          margin: 0,
          marginTop: theme.spacing(4),
          fontSize: `calc(${theme.typography.h6.fontSize} * 0.85)`,
          fontWeight: 600,
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(2, 0),
          width: '100%',
          textTransform: 'none',
          boxShadow: theme.shadows[3],
          '&:hover': {
            boxShadow: theme.shadows[4],
          },
          whiteSpace: 'normal',
          lineHeight: 1.2,
        }}
      >
        {buttonText || t('keepCleaningYourInbox')}
      </Button>
    </>
  );
}

export default PlanSelection;
