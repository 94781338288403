import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import AnalyticsService from '../services/analytics/analyticsService';
import { UTM_SOURCE_KEY } from '../constants';

export function useGetURLParams() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [userEmailFromUrl, setUserEmail] = useState<string>();

  const sessionId = searchParams.get('session_id');
  useEffect(() => {
    if (!sessionId) {
      return;
    }
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.delete('session_id');
      return newSearchParams;
    });
  }, [sessionId]);

  const loginLinkId = searchParams.get('login_link_id');
  useEffect(() => {
    if (!loginLinkId) {
      return;
    }
    setUserEmail(loginLinkId);
    setSearchParams((prevSearchParams) => {
      const newSearchParams = new URLSearchParams(prevSearchParams);
      newSearchParams.delete('login_link_id');
      return newSearchParams;
    });
  }, [loginLinkId]);

  const utm_source = searchParams.get('utm_source');

  useEffect(() => {
    if (!utm_source) {
      return;
    }

    localStorage.setItem(UTM_SOURCE_KEY, utm_source);

    if (utm_source === 'Facebook') {
      AnalyticsService.setFacebookUser();
    }
  }, [utm_source]);

  const email = searchParams.get('email');
  useEffect(() => {
    if (!email) {
      return;
    }

    setUserEmail(email);
  }, [email]);

  const [nativeAppFunnelMode, setNativeAppFunnelMode] = useState<boolean>(false);

  const native_mode = searchParams.get('native_mode');
  useEffect(() => {
    if (!native_mode) {
      return;
    }
    setNativeAppFunnelMode(true);
  }, [native_mode]);

  return { userEmail: userEmailFromUrl, nativeAppFunnelMode };
}
