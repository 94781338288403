import React, { useCallback, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, Chip, Stack, useMediaQuery } from '@mui/material';
import { useLiveQuery } from 'dexie-react-hooks';
import { t } from 'i18next';
import { useSnackbar, OptionsObject } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useLoadPricingPage } from '../../app/useLoadPricingPage';
import { TRIMBOX_REDIRECT_KEY } from '../../constants';
import {
  PlanTypePresentable,
  CurrencyConfig,
  PromoCode,
} from '../../features/offering/offeringSlice.types';
import { getCurrentMailboxQuery } from '../../model/mailbox';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { SCOPE_TYPE } from '../../services/credentialFactory';
import MailboxConnector from '../../services/mailboxConnector';
import theme, { WHITE_HEX, PRIMARY_COLOR_HEX, PURPLE_HEX, LIGHT_GRAY_HEX } from '../../theme';
import { openCheckoutPage } from '../../utilities/paywallHelper';
import PaywallBullet from './PaywallBullet';
import TopBar from './TopBar';
import DiscountIcon from '@mui/icons-material/Discount';
import PlansContainer from '../../components/PlansPopup/PlansContainer/PlansContainer';
import HorizontalPlansContainer from './HorizontalPlansContainer';
import { WebViewDetector } from '../../services/webViewDetector/webViewDetector';

export type PricingPageProps = {
  userEmailFromURL?: string;
  nativeAppFunnelMode?: boolean;
};

export default function PricingPage(props: PricingPageProps) {
  const { userEmailFromURL, nativeAppFunnelMode } = props;

  const mailbox = useLiveQuery(getCurrentMailboxQuery);

  const userEmail = mailbox?.email_address || userEmailFromURL;

  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const isIOS = WebViewDetector.getDeviceOS(navigator.userAgent) === 'ios';
  const supportsAppStore = isIOS;

  const BULLETS: string[] = [
    'unlimitedUnsubscribes',
    'unlimitedBulkDeletes',
    'forAllInboxes',
    ...(supportsAppStore ? ['cleanPhotos'] : []),
    'neverSellData',
  ];

  const navigate = useNavigate();

  const {
    discountPercentage,
    includeLifetime,
    promoCode,
    returnUrl: returnUrlFromParams,
    source,
  } = useLoadPricingPage(mailbox);

  const installUrl = window.location.origin + '/install';
  const returnUrl = nativeAppFunnelMode ? installUrl : returnUrlFromParams;

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (nativeAppFunnelMode) {
      AnalyticsService.track(EventName.UPGRADE_PAGE_VISIT);
    }
  }, [nativeAppFunnelMode]);

  function showErrorToast(e: unknown) {
    const snackbarOptions: OptionsObject = {
      variant: 'error',
      preventDuplicate: true,
      anchorOrigin: { horizontal: 'center', vertical: 'top' },
      persist: false,
    };

    const errorMessage = t('failedToSignIn');

    enqueueSnackbar(errorMessage, snackbarOptions);
  }

  const onPlanCheckout = useCallback(
    async (
      plan: PlanTypePresentable,
      currency: CurrencyConfig,
      promoCode: PromoCode | undefined
    ) => {
      if (plan.amountWithDiscount == 0) {
        navigate('/');
        return;
      }

      AnalyticsService.track(EventName.PRICING_PAGE_PLAN_SELECT, {
        planInterval: plan.interval,
        planAmount: plan.amountWithoutDiscount,
        includeFree: aboveMedium,
        discountPercentage,
        includeLifetime,
        hasEmail: !!userEmail,
      });

      if (!userEmail) {
        AnalyticsService.track(EventName.CLICK_LOGIN);

        AnalyticsService.track(EventName.LOGIN_START);

        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          const g = google;

          localStorage.setItem(TRIMBOX_REDIRECT_KEY, window.location.href);

          MailboxConnector.create(g).startConnection(SCOPE_TYPE.USER_INFO);
        } catch (e: unknown) {
          AnalyticsService.trackError(EventName.LOGIN_FAILED, e);

          showErrorToast(e);
        }
        return;
      }

      try {
        await openCheckoutPage({
          mailboxId: userEmail,
          selectedPlan: plan.planType,
          currency,
          promoCode: promoCode?.id,
          returnUrl,
          source,
        });
      } catch (e) {
        enqueueSnackbar(t('somethingWentWrong'), {
          variant: 'error',
          preventDuplicate: true,
          anchorOrigin: { horizontal: 'center', vertical: 'top' },
          persist: false,
        });
        AnalyticsService.trackError(EventName.CHECKOUT_FAILED, e);
      }
    },
    [userEmail, discountPercentage, includeLifetime, promoCode, returnUrl, source, aboveMedium]
  );

  const onDecline = useCallback(() => {
    AnalyticsService.track(EventName.UPGRADE_PAGE_DECLINE);
    navigate('/');
  }, [navigate]);

  const renderHorizontalPlans = () => {
    return (
      <Container maxWidth='lg' component='main'>
        <HorizontalPlansContainer onCheckout={onPlanCheckout} />
      </Container>
    );
  };

  const renderVerticalPlans = () => {
    return (
      <Container maxWidth='sm' component='main' sx={{ marginTop: aboveMedium ? 0 : 4 }}>
        <PlansContainer
          mailbox={mailbox}
          buttonText={t('unlockAndStartCleaning') as string}
          onCheckout={onPlanCheckout}
        />
      </Container>
    );
  };

  return (
    <Stack
      sx={{
        minHeight: '100vh',
        background: WHITE_HEX,
        gap: aboveMedium ? 4 : 2,
      }}
    >
      <TopBar userEmail={userEmail} />
      <Container
        disableGutters
        maxWidth='lg'
        component='main'
        sx={{
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!!discountPercentage && (
          <Chip
            icon={<DiscountIcon color='white' fontSize='small' />}
            sx={{
              background: `linear-gradient(120deg,${PRIMARY_COLOR_HEX} 13.4%,${PURPLE_HEX} 86.6%)`,
              color: WHITE_HEX,
              fontWeight: 600,
              marginBottom: aboveSmall ? 5 : 4,
              marginTop: aboveSmall ? '-4rem' : 0,
              padding: 2,
              fontSize: '1rem',
            }}
            label={t('xOffAllPlans', { percentage: discountPercentage * 100 })}
          />
        )}
        <Typography
          component='h1'
          variant='h2'
          fontWeight={800}
          align='center'
          color='text.primary'
          maxWidth={aboveMedium ? 'unset' : '80%'}
          fontSize={aboveMedium ? '4rem' : aboveSmall ? '2.5rem' : '2rem'}
        >
          {t(nativeAppFunnelMode ? 'unlockUnlimitedCleaningAccess' : 'pricingPageTitle')}
        </Typography>
        {/* {!nativeAppFunnelMode && (
          <Typography
            variant='h5'
            align='center'
            color='text.primary'
            component='p'
            mt={'1rem'}
            fontSize={aboveMedium ? '1.6rem' : '1.2rem'}
          >
            {t('pricingPageSubtitle')}
          </Typography>
        )} */}
      </Container>
      <Box
        sx={{
          display: 'flex',
          rowGap: aboveMedium ? 3 : 2,
          columnGap: aboveMedium ? 3 : 2,
          flexWrap: 'wrap',
          justifyContent: 'center',
          px: 2,
          borderBottom: aboveMedium ? `1px ${LIGHT_GRAY_HEX} solid` : 'unset',
          pb: aboveMedium ? 6 : 0,
        }}
      >
        {BULLETS.map((bullet) => (
          <PaywallBullet key={bullet} text={t(bullet)} />
        ))}
      </Box>
      {aboveMedium ? renderHorizontalPlans() : renderVerticalPlans()}
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {nativeAppFunnelMode && (
          <Button
            variant='text'
            size='medium'
            color='gray'
            sx={{
              margin: aboveSmall ? '1rem' : isExtraSmall ? '0.5rem' : '0.75rem',
            }}
            onClick={onDecline}
          >
            {t('notNow')}
          </Button>
        )}
      </Box>
    </Stack>
  );
}
