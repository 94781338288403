import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';
import { useTranslation } from 'react-i18next';
import { LandingPageFlavorEnum } from './welcomeFlavors.types';
import mailIcon from '../../../../assets/mail-icon.png';
import photosIcon from '../../../../assets/photos-icon.png';
import WelcomeHeader from '../components/WelcomeHeader';
import banner_image from '../../../../assets/banner_image.svg';
import BadgeIcon from '../../../common/BadgeIcon';
import ProgressIndicator from '../../../common/ProgressIndicator';
import LargeActionButton from '../../../common/LargeActionButton';
import { useMockStorageProgress } from './useMockStorageProgress';

const MOCK_STORAGE_TARGET_VALUE = 0.2;

export type WelcomePhotoCleanerProps = {
  landingPageFlavor: LandingPageFlavorEnum;
  nativeAppFunnelMode: boolean;
  next: () => void;
};

function WelcomePhotoCleaner(props: WelcomePhotoCleanerProps) {
  const { next } = props;
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();
  const { progressValue, bytesSavedValue, progressColor } = useMockStorageProgress();

  const [photosBadgeCount, setPhotosBadgeCount] = useState(698);
  const [iCloudBadgeCount, setICloudBadgeCount] = useState(785);

  useEffect(() => {
    AnalyticsService.track(EventName.NATIVE_FUNNEL_START);
  }, []);

  useEffect(() => {
    const normalizedProgressValue = progressValue - MOCK_STORAGE_TARGET_VALUE;
    const photosRange = 698 - 6;
    const iCloudRange = 785 - 4;

    const newPhotosCount = Math.round(6 + photosRange * normalizedProgressValue);
    const newICloudCount = Math.round(4 + iCloudRange * normalizedProgressValue);

    setPhotosBadgeCount(newPhotosCount);
    setICloudBadgeCount(newICloudCount);
  }, [progressValue]);

  return (
    <>
      <Box
        margin={2}
        marginBottom={0}
        component='img'
        src={banner_image}
        sx={{
          height: aboveSmall ? 36 : isExtraSmall ? 24 : 32,
        }}
      />
      <WelcomeHeader flavor={LandingPageFlavorEnum.PHOTO_CLEANER} />

      <Box
        sx={{
          display: 'flex',
          gap: aboveSmall ? 8 : 6,
          padding: '2rem 0',
        }}
      >
        <BadgeIcon icon={mailIcon} badgeCount={photosBadgeCount} alt='Photos' />
        <BadgeIcon icon={photosIcon} badgeCount={iCloudBadgeCount} alt='iCloud' />
      </Box>

      <ProgressIndicator
        progress={progressValue}
        progressColor={progressColor}
        label={t('xGBSaved', { count: bytesSavedValue })}
      />

      <LargeActionButton onClick={next}>{t('getStarted')}</LargeActionButton>
    </>
  );
}

export default WelcomePhotoCleaner;
