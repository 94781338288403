export enum LandingPageFlavorEnum {
  CLEAN_EMAIL = 'clean_email',
  UNSUBSCRIBE = 'unsubscribe',
  DELETE = 'delete',
  // TODO
  MARK_AS_READ = 'mark_as_read',
  GMAIL_CLEANER = 'gmail_cleaner',
  HOW_TO_UNSUBSCRIBE = 'how_to_unsubscribe',
  FREE_CLEANER = 'free_cleaner',
  STOP_SPAM = 'stop_spam',
  BLOCK_SPAM = 'block',
  HOW_TO_GMAIL_DELETE = 'how_to_gmail_delete',
  GMAIL_DELETE = 'gmail_delete',
  UNSUBSCRIBE_GMAIL = 'unsubscribe_gmail',
  UNSUBSCRIBE_MAILING_LIST = 'unsubscribe_mailing_list',
  UNSUBSCRIBE_APP = 'unsubscribe_app',
  PHOTO_CLEANER = 'photo_cleaner',
}
