import React from 'react';

import { Button, useMediaQuery, useTheme } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export type WelcomeNativeAppFunnelFooterProps = {
  next: () => void;
};

function WelcomeNativeAppFunnelFooter(props: WelcomeNativeAppFunnelFooterProps) {
  const { next } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Button
      variant='contained'
      size='large'
      color='googleBlue'
      sx={{
        fontSize: aboveSmall ? '1.4rem' : isExtraSmall ? '1.2rem' : '1.3rem',
        margin: aboveSmall ? '1.5rem' : isExtraSmall ? '0.75rem' : '1rem',
        marginBottom: aboveSmall ? '2.5rem' : isExtraSmall ? '1.5rem' : '2rem',
        fontWeight: 700,
        padding: '1rem 2rem',
        borderRadius: '50px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        transition: 'box-shadow 0.3s ease-in-out',
        width: '90%',
        maxWidth: '650px',
        '&:hover': {
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.15)',
        },
      }}
      endIcon={<ArrowForward />}
      onClick={next}
    >
      {t('getStarted')}
    </Button>
  );
}

export default WelcomeNativeAppFunnelFooter;
