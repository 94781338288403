import { useState, useEffect } from 'react';
import { RED_COLOR_HEX } from '../../../../theme';

export const MOCK_STORAGE_TARGET_VALUE = 0.4;
export const MOCK_STORAGE_INITIAL_VALUE = 1;

export function useMockStorageProgress() {
  const [progressValue, setProgressValue] = useState<number>(1);

  useEffect(() => {
    const startDelay = 1000; // Delay before starting the animation
    const duration = 2000; // Duration of the animation
    const repeatDelay = 4000; // Delay before repeating

    const step = ((MOCK_STORAGE_INITIAL_VALUE - MOCK_STORAGE_TARGET_VALUE) / duration) * 50; // Calculate step value to decrease every 50ms

    const runAnimation = () => {
      setProgressValue(MOCK_STORAGE_INITIAL_VALUE); // Reset to initial value

      const interval = setInterval(() => {
        setProgressValue((prevValue) => {
          const nextValue = prevValue - step;
          if (nextValue <= MOCK_STORAGE_TARGET_VALUE) {
            clearInterval(interval);
            // Schedule next animation after reaching target
            setTimeout(runAnimation, repeatDelay);
            return MOCK_STORAGE_TARGET_VALUE;
          }
          return nextValue;
        });
      }, 50);

      return interval;
    };

    const timer = setTimeout(() => {
      const interval = runAnimation();
      return () => clearInterval(interval);
    }, startDelay);

    return () => clearTimeout(timer);
  }, []); // Empty dependency array ensures this effect only runs once after the initial render

  const [progressColor, setProgressColor] = useState<string>(RED_COLOR_HEX);

  useEffect(() => {
    const interpolateColor = (value: number, startValue: number, endValue: number) => {
      // Reverse the interpolation direction by adjusting the formula
      return Math.round(startValue + (endValue - startValue) * (1 - value));
    };

    const getHexColor = (value: number) => {
      // Normalize the progressValue from [0.4, 1] to [0, 1]
      const normalizedValue = (value - 0.4) / 0.6; // Adjusting the denominator to span from 0.4 to 1

      // Red color at progressValue 1 is #D30000 (211, 0, 0)
      // Green color at progressValue 0.4 is #31b044 (49, 176, 68)
      const red = interpolateColor(normalizedValue, 211, 49); // Red decreases
      const green = interpolateColor(normalizedValue, 0, 176); // Green increases
      const blue = interpolateColor(normalizedValue, 0, 68); // Blue increases

      return `#${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue
        .toString(16)
        .padStart(2, '0')}`;
    };

    setProgressColor(getHexColor(progressValue));
  }, [progressValue]); // This effect depends on progressValue

  const isDone = progressValue === 0.4;
  const gbValue = isDone ? 125 : (1 - progressValue) * 208;
  const bytesSavedValue = Math.round(gbValue);

  return {
    bytesSavedValue,
    progressColor,
    progressValue,
  };
}
