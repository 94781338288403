import React, { useCallback, useEffect } from 'react';

import { useLiveQuery } from 'dexie-react-hooks';
import { Routes, Route } from 'react-router-dom';
import { useGetURLParams } from './app/useGetURLParams';
import { useIdentification } from './app/useIdentification';
import Mailbox, { getCurrentMailboxQuery } from './model/mailbox';
import MainComponent from './MainComponent';
import LoadingPage from './components/Loading/LoadingPage';
import IntroPage from './components/intro/IntroPage';
import { useGetMailboxData } from './app/useGetMailboxData';
import { useFetchOffering } from './app/useFetchOffering';
import ABTestingService from './services/abTestingService';
import PricingPage from './screens/PricingPage/PricingPage';
import UninstallPage from './screens/UninstallPage/UninstallPage';
import LicensesPage from './screens/LicensesPage/LicensesPage';
import { LandingPageFlavorEnum } from './components/intro/onboarding/Welcome/welcomeFlavors.types';
import InstallPage from './screens/InstallPage/InstallPage';

function App() {
  useEffect(() => {
    console.log(`Version: ${process.env.REACT_APP_VERSION}`);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.TrackJS) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      TrackJS.configure({ version: process.env.REACT_APP_VERSION });
    }

    ABTestingService.initialize();
  }, []);

  const mailbox: Mailbox | undefined | null = useLiveQuery(getCurrentMailboxQuery);
  const { isMailboxQueryPending, hasTokenAndFullScopes, hasTokenAndUserInfoScopes } =
    useGetMailboxData(mailbox);

  const { userEmail } = useGetURLParams();

  useIdentification(mailbox, userEmail);

  useFetchOffering();

  const renderMainComponent = useCallback(
    (landingPageFlavor: LandingPageFlavorEnum, nativeAppFunnelMode = false) => {
      let itemToRender;
      if (isMailboxQueryPending) {
        itemToRender = <LoadingPage />;
      } else if (hasTokenAndFullScopes) {
        itemToRender = <MainComponent />;
      } else {
        itemToRender = (
          <IntroPage
            landingPageFlavor={landingPageFlavor}
            nativeAppFunnelMode={nativeAppFunnelMode}
          />
        );
      }
      return itemToRender;
    },
    [isMailboxQueryPending, hasTokenAndFullScopes, hasTokenAndUserInfoScopes, mailbox]
  );

  return (
    <Routes>
      <Route path='/pricing' element={<PricingPage userEmailFromURL={userEmail} />} />
      <Route path='/uninstall' element={<UninstallPage userEmailFromURL={userEmail} />} />
      <Route path='/unlimited' element={<LicensesPage />} />
      <Route
        path='/gmail-cleaner'
        element={renderMainComponent(LandingPageFlavorEnum.GMAIL_CLEANER)}
      />
      <Route path='/clean-email' element={renderMainComponent(LandingPageFlavorEnum.CLEAN_EMAIL)} />
      <Route
        path='/how-to-unsubscribe'
        element={renderMainComponent(LandingPageFlavorEnum.HOW_TO_UNSUBSCRIBE)}
      />
      <Route path='/unsubscribe' element={renderMainComponent(LandingPageFlavorEnum.UNSUBSCRIBE)} />
      <Route path='/stop-spam' element={renderMainComponent(LandingPageFlavorEnum.STOP_SPAM)} />
      <Route
        path='/free-email-cleaner'
        element={renderMainComponent(LandingPageFlavorEnum.FREE_CLEANER)}
      />
      <Route path='/block-spam' element={renderMainComponent(LandingPageFlavorEnum.BLOCK_SPAM)} />
      <Route
        path='/how-to-gmail-delete'
        element={renderMainComponent(LandingPageFlavorEnum.HOW_TO_GMAIL_DELETE)}
      />
      <Route
        path='/gmail-delete'
        element={renderMainComponent(LandingPageFlavorEnum.GMAIL_DELETE)}
      />
      <Route
        path='/gmail-unsubscribe'
        element={renderMainComponent(LandingPageFlavorEnum.UNSUBSCRIBE_GMAIL)}
      />
      <Route
        path='/mailing-list-unsubscribe'
        element={renderMainComponent(LandingPageFlavorEnum.UNSUBSCRIBE_MAILING_LIST)}
      />
      <Route
        path='/unsubscribe-app'
        element={renderMainComponent(LandingPageFlavorEnum.UNSUBSCRIBE_APP)}
      />
      <Route path='/delete' element={renderMainComponent(LandingPageFlavorEnum.DELETE)} />
      <Route path='/unsubscribe' element={renderMainComponent(LandingPageFlavorEnum.UNSUBSCRIBE)} />
      <Route
        path='/native'
        element={renderMainComponent(LandingPageFlavorEnum.CLEAN_EMAIL, true)}
      />
      <Route path='/install' element={<InstallPage />} />
      <Route
        path='/upgrade'
        element={<PricingPage userEmailFromURL={userEmail} nativeAppFunnelMode={true} />}
      />
      <Route path='/' element={renderMainComponent(LandingPageFlavorEnum.CLEAN_EMAIL)} />
    </Routes>
  );
}

export default App;
