import React from 'react';
import { Box, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DARK_GRAY_HEX, WHITE_HEX } from '../../theme';

interface ProgressIndicatorProps {
  progress: number;
  progressColor: string;
  label: string;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  progress,
  progressColor,
  label,
}) => {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={{ width: '100%', maxWidth: 400, margin: '0 auto' }}>
      <Box sx={{ mx: 8 }}>
        <LinearProgress
          variant='determinate'
          value={progress * 100}
          sx={{
            height: 24,
            borderRadius: 3,
            backgroundColor: WHITE_HEX,
            '& .MuiLinearProgress-bar': {
              backgroundColor: progressColor,
            },
          }}
        />
      </Box>
      <Typography
        sx={{
          textAlign: 'center',
          marginTop: 3,
          color: DARK_GRAY_HEX,
          fontSize: aboveSmall ? '1.5rem' : '1.25rem',
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export default ProgressIndicator;
