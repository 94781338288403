import React, { useEffect } from 'react';

import './IntroPage.scss';
import { Stack } from '@mui/material';
import { GHOST_WHITE_HEX } from '../../theme';
import OnboardingFlow from './onboarding/OnboardingFlow';
import { WebViewDetector } from '../../services/webViewDetector/webViewDetector';
import { LandingPageFlavorEnum } from './onboarding/Welcome/welcomeFlavors.types';
import NativeAppFunnelFlow from './onboarding/NativeAppFunnelFlow';

export type IntroPageProps = {
  landingPageFlavor: LandingPageFlavorEnum;
  nativeAppFunnelMode: boolean;
};

function IntroPage(props: IntroPageProps) {
  const vh = window.innerHeight * 0.01;

  const { landingPageFlavor, nativeAppFunnelMode } = props;

  // Set the CSS variable to the root element
  // Which is equal to 1vh
  document.documentElement.style.setProperty('--vh', vh + 'px');

  useEffect(() => {
    const webViewInfo = WebViewDetector.checkForWebView();
    if (webViewInfo?.isInApp) {
      window.location.href = webViewInfo.appStoreLink;
    }
  }, []);

  return (
    <Stack
      direction='column'
      alignItems='center'
      className='full-height-stack blue-gradient'
      sx={{
        backgroundColor: GHOST_WHITE_HEX,
      }}
    >
      {nativeAppFunnelMode ? (
        <NativeAppFunnelFlow landingPageFlavor={landingPageFlavor} />
      ) : (
        <OnboardingFlow landingPageFlavor={landingPageFlavor} />
      )}
    </Stack>
  );
}
export default IntroPage;
