import React from 'react';

import { useTheme, useMediaQuery, Typography, Stack, Radio } from '@mui/material';
import { GREEN_HEX, GRAY_HEX, LAWN_GREEN_HEX, LIGHTER_GRAY_HEX } from '../../../../theme';
import {
  CurrencyConfig,
  PlanTypePresentable,
} from '../../../../features/offering/offeringSlice.types';
import { formatPrice } from '../../../../features/offering/currencyHelper';
import { useTranslation } from 'react-i18next';

export type PlanOptionProps = {
  plan: PlanTypePresentable;

  isSelected: boolean;
  onSelect: (plan: PlanTypePresentable) => void;

  hasFeedbackDiscount?: boolean;

  percentOff: number | null;

  currency: CurrencyConfig;
};

function PlanOption(props: PlanOptionProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const { t } = useTranslation();

  const { plan, hasFeedbackDiscount, currency, isSelected, onSelect, percentOff } = props;

  let presentedPriceString = formatPrice(plan.presentedPrice, currency);

  if (plan.interval !== 'lifetime') {
    presentedPriceString = t('xPerMonth', {
      price: presentedPriceString,
    });
  }

  let billingPeriodText = '';

  if (plan.interval === 'year') {
    billingPeriodText = t('billedAnnuallyX', {
      price: formatPrice(plan.amountWithDiscount, currency),
    });
  } else if (plan.interval === 'month') {
    billingPeriodText = t('billedMonthly');
  } else if (plan.interval === 'lifetime') {
    billingPeriodText = t('lifetimeAccess');
  } else {
    throw new Error('Unrecognized interval');
  }

  return (
    <Stack
      onClick={() => {
        onSelect(plan);
      }}
      sx={{
        cursor: 'pointer',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: LIGHTER_GRAY_HEX,
        borderRadius: '5px',
        padding: '0.5rem',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02)',
      }}
    >
      <Radio
        checked={isSelected}
        onChange={() => {
          onSelect(plan);
        }}
        value='a'
        name='radio-buttons'
        inputProps={{ 'aria-label': 'A' }}
        sx={{ padding: 0, margin: '0 0.25rem' }}
      />
      <Stack
        sx={{
          flexDirection: 'column',
          marginLeft: '0.5rem',
          flexGrow: 1,
        }}
      >
        <Stack
          sx={{
            flexDirection: 'row',
          }}
        >
          {hasFeedbackDiscount && (
            <Typography
              fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '0.85rem' : '0.85rem'}
              marginRight='0.25rem'
              sx={{ textDecoration: 'line-through' }}
            >
              {`${formatPrice(plan.maximizedPrice, currency)}`}
            </Typography>
          )}
          <Typography
            fontSize={isExtraSmall ? '0.75rem' : aboveSmall ? '0.85rem' : '0.85rem'}
            display='flex'
            fontWeight={500}
            sx={{ ...(hasFeedbackDiscount ? { color: GREEN_HEX } : {}) }}
          >
            {presentedPriceString}
          </Typography>
        </Stack>
        <Typography
          fontSize={isExtraSmall ? '0.65rem' : aboveSmall ? '0.7rem' : '0.7rem'}
          marginTop='0.25rem'
          color={GRAY_HEX}
        >
          {billingPeriodText}
        </Typography>
      </Stack>
      {percentOff && !hasFeedbackDiscount && (
        <Typography
          fontSize={isExtraSmall ? '0.65rem' : aboveSmall ? '0.85rem' : '0.75rem'}
          fontWeight={700}
          padding={aboveSmall ? '6px 10px' : '4px 6px'}
          whiteSpace={'nowrap'}
          borderRadius='1rem'
          sx={{ backgroundColor: LAWN_GREEN_HEX }}
        >
          {t('xPercentOff', { percent: percentOff })}
        </Typography>
      )}
      {plan.interval === 'lifetime' && (
        <Typography
          fontSize={isExtraSmall ? '0.65rem' : aboveSmall ? '0.85rem' : '0.75rem'}
          fontWeight={700}
          padding={aboveSmall ? '6px 10px' : '4px 8px'}
          whiteSpace={'nowrap'}
          borderRadius='1rem'
          sx={{ backgroundColor: LAWN_GREEN_HEX }}
        >
          {t('noSubscription')}
        </Typography>
      )}
    </Stack>
  );
}
export default PlanOption;
