import { Dict } from 'mixpanel-browser';
import Mailbox from '../../../model/mailbox';
import { CheckoutParams } from '../analyticsService.types';

export default interface AnalyticsProvider {
  identify: (mailbox: Mailbox) => void;

  alias: (id: string) => void;

  track: (name: EventName, props?: Dict) => void;

  trackCheckout: (checkoutParams: CheckoutParams) => void;

  trackError: (name: EventName, errorProps: ErrorDict) => void;
}

export enum EventName {
  // When the user hits the Welcome page
  GET_STARTED_VISIT = 'get_started_visit',
  GET_STARTED_CLICK = 'get_started_click',

  NATIVE_FUNNEL_START = 'native_funnel_start',

  CAPTURE_UA = 'capture_ua',
  SHOW_ESCAPE_WEBVIEW_PAGE = 'show_escape_webview_page',

  CLICK_LOGIN = 'click_login',

  LOGIN_START = 'login_start',
  LOGIN_SUCCESSFUL = 'login_successful',
  LOGIN_FAILED = 'login_failed',

  CLICK_CONNECT_MAILBOX = 'click_connect_mailbox',
  CONNECT_MAILBOX_SUCCESSFUL = 'connect_mailbox_successful',
  CONNECT_MAILBOX_FAILED = 'connect_mailbox_failed',

  PERMISSIONS_INFO_SHOW = 'permissions_info_show',

  CLICK_USE_ANOTHER_PROVIDER = 'click_use_another_provider',
  REQUEST_PROVIDER = 'request_provider',

  LOADED_EMAIL_LISTS = 'load_email_lists',

  CLICK_UNSUBSCRIBE = 'click_unsubscribe',
  // UNSUBSCRIBE_SUCCESSFUL = 'unsubscribe_successful',
  UNSUBSCRIBE_FAILED = 'unsubscribe_failed',

  SMTP_UNSUBSCRIBE_FAILED = 'smtp_unsubscribe_failed',

  HTTP_UNSUBSCRIBE_FAILED = 'http_unsubscribe_failed',

  CLICK_KEEP = 'click_keep',
  // KEEP_SUCCESSFUL = 'keep_successful',
  KEEP_FAILED = 'keep_failed',

  // DELETE_ALL_STARTED = 'delete_all_started',
  DELETE_ALL_SUCCESSFUL = 'delete_all_successful',
  DELETE_ALL_FAILED = 'delete_all_failed',

  CLICK_DELETE_BUNDLE = 'click_delete_bundle',
  DELETE_BUNDLE_SUCCESSFUL = 'delete_bundle_successful',
  DELETE_BUNDLE_FAILED = 'delete_bundle_failed',
  DELETE_ALL_OPERATION_FAILED = 'delete_all_operation_failed',

  SYNC_START = 'sync_start',
  SYNC_COMPLETE = 'sync_complete',
  SYNC_FAILED = 'sync_failed',
  SYNC_ATTEMPT_FAILED = 'sync_attempt_failed',

  SYNC_ERROR = 'sync_error',

  PARSE_MESSAGE_FAILED = 'parse_message_failed',

  PAYWALL_SHOW = 'paywall_show',
  PAYWALL_CONFIRM = 'paywall_confirm',
  PAYWALL_DENY = 'paywall_deny',

  PLANS_SHOW = 'plans_show',
  PLANS_CONFIRM = 'plans_confirm',
  PLANS_DENY = 'plans_deny',

  CHURN_SHOW = 'churn_show',
  CHURN_CONFIRM = 'churn_confirm',
  CHURN_DENY = 'churn_deny',
  CHURN_CLAIM_DISCOUNT = 'churn_claim_discount',

  CHECKOUT_CAPTURE = 'checkout_capture',
  CHECKOUT_COMPLETE = 'checkout_complete',
  CHECKOUT_FAILED = 'checkout_failed',
  LOG_CHECKOUT_SESSION_ERROR = 'log_checkout_session_error',
  GET_CHECKOUT_SESSION_ERROR = 'get_checkout_session_error',

  MANAGE_PREMIUM_FAILED = 'manage_premium_failed',

  VIEW_PROGRESS_FAILED = 'view_progress_failed',

  UNKNOWN_ERROR = 'unknown_error',

  // QUEUE_FILTER_OPERATION = 'queue_filter_operation',
  // START_FILTER_OPERATION = 'start_filter_operation',
  // END_FILTER_OPERATION = 'end_filter_operation',
  FILTER_QUEUE_FAILED = 'filter_queue_failed',
  // RERUN_FILTER_OPERATION = 'rerun_filter_operation',
  FILTER_LIST_FAILED = 'filter_list_failed',

  DUPLICATE_FILTER_ID_FOUND = 'duplicate_filter_id_found',

  // CREATE_FILTER_SUCCESS = 'create_filter_success',
  CREATE_FILTER_ATTEMPT_FAILED = 'create_filter_attempt_failed',
  CREATE_FILTER_FAILED = 'create_filter_failed',

  // DELETE_FILTER_SUCCESS = 'delete_filter_success',
  DELETE_FILTER_ATTEMPT_FAILED = 'delete_filter_attempt_failed',
  DELETE_FILTER_FAILED = 'delete_filter_failed',

  DELETE_FILTER_BY_ID_FAILED = 'delete_filter_by_id_failed',
  DELETE_FILTER_BY_QUERY_SUCCESS = 'delete_filter_by_query_success',
  DELETE_FILTER_BY_QUERY_FAILED = 'delete_filter_by_query_failed',

  FILTER_SUBSCRIPTION_FAILED = 'filter_subscription_failed',

  CREATE_BOUNCEBACK_FILTER_FAILED = 'create_bounceback_filter_failed',

  REFRESH_CREDENTIAL = 'refresh_credential',

  SEND_WEBVIEW_LOGIN = 'send_webview_login',
  SEND_WEBVIEW_LOGIN_ERROR = 'send_webview_login_error',

  PRICING_PAGE_VIEW = 'pricing_page_view',
  PRICING_PAGE_PLAN_SELECT = 'pricing_page_plan_select',

  UNINSTALL_PAGE_VIEW = 'uninstall_page_view',
  UNINSTALL_PAGE_UNLOCK_DISCOUNT = 'uninstall_page_unlock_discount',
  UNINSTALL_PAGE_CLAIM_DISCOUNT = 'uninstall_page_claim_discount',

  ENTER_EMAIL_VISIT = 'enter_email_visit',
  UPGRADE_PAGE_VISIT = 'upgrade_page_visit',
  UPGRADE_PAGE_DECLINE = 'upgrade_page_decline',
}

export interface ErrorDict {
  errorMessage: string;
  isError: true;
  [key: string]: unknown;
}
