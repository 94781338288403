import React from 'react';

import { CircularProgress, Stack } from '@mui/material';

import { PlanTypePresentable } from '../../../../features/offering/offeringSlice.types';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  selectSelectedPlanInterval,
  setSelectedPlan,
} from '../../../../features/offering/offeringSlice';
import PlanOption from './PlanOption';
import { useGetOffering } from '../../../../app/useGetOffering';
import { getPercentOff } from '../../../../features/offering/plans.helpers';
import Mailbox from '../../../../model/mailbox';

export type PlansListProps = {
  hasFeedbackDiscount: boolean;
  mailbox: Mailbox | null | undefined;
};

function PlansList(props: PlansListProps) {
  const { hasFeedbackDiscount, mailbox } = props;

  const { plans, currency } = useGetOffering({ mailbox });
  const selectedPlanInterval = useAppSelector(selectSelectedPlanInterval);

  const dispatch = useAppDispatch();

  if (!plans || !currency) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  return (
    <Stack direction='column' gap={2}>
      {plans.map((plan, i) => {
        return (
          <PlanOption
            key={plan.interval}
            plan={plan}
            percentOff={getPercentOff(plans, plan)}
            isSelected={selectedPlanInterval === plan.interval}
            onSelect={(selectedPlan: PlanTypePresentable) => {
              dispatch(setSelectedPlan(selectedPlan.interval));
            }}
            hasFeedbackDiscount={hasFeedbackDiscount}
            currency={currency}
          />
        );
      })}
    </Stack>
  );
}

export default PlansList;
