import React, { useEffect, useState } from 'react';

import { useMediaQuery, Stack, Typography, useTheme, Box, Button, TextField } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import banner_image from '../../../../assets/banner_image.svg';
import { useTranslation } from 'react-i18next';
import AnalyticsService from '../../../../services/analytics/analyticsService';
import { EventName } from '../../../../services/analytics/providers/analyticsProvider';

export type EnterEmailProps = {
  next: (email: string) => void;
};

function EnterEmail({ next }: EnterEmailProps) {
  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const aboveMedium = useMediaQuery(theme.breakpoints.up('md'));

  const [email, setEmail] = useState<string | undefined>();
  const [invalidEmail, setInvalidEmail] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    AnalyticsService.track(EventName.ENTER_EMAIL_VISIT);
  }, []);

  useEffect(() => {
    if (!email) {
      setInvalidEmail(false);
      return;
    }

    const validateEmail = (email: string) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    setInvalidEmail(!validateEmail(email));
  }, [email]);

  const onContinue = async () => {
    if (email) {
      next(email);
    }
  };

  return (
    <Stack
      direction='column'
      alignItems='center'
      sx={{
        minHeight: '100vh',
      }}
    >
      <Box
        margin={2}
        marginBottom={0}
        component='img'
        src={banner_image}
        sx={{
          height: aboveSmall ? 36 : isExtraSmall ? 24 : 32,
        }}
      />
      <Stack
        flexGrow={1}
        sx={{
          margin: aboveSmall ? '0rem' : '1rem',
          marginTop: aboveSmall ? '8rem' : '4rem',
        }}
      >
        <>
          <Typography
            variant='h4'
            component='h4'
            fontWeight={600}
            sx={{
              fontSize: isExtraSmall ? '1.75rem' : aboveSmall ? '3rem' : '2rem',
              marginBottom: aboveMedium ? '2rem' : '1rem',
            }}
          >
            {t('letsCleanYourInbox')}
          </Typography>
          <TextField
            error={invalidEmail}
            type={'email'}
            helperText={invalidEmail && t('enterValidEmail')}
            sx={{
              margin: isExtraSmall ? '2rem 0rem' : '1.5rem 0rem',
              maxWidth: '650px',
              '& .MuiOutlinedInput-root': {
                fontSize: aboveSmall ? '1.2rem' : isExtraSmall ? '1rem' : '1.1rem',
                padding: '0.75rem 1.25rem',
                borderRadius: '50px',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backdropFilter: 'blur(10px)',
                transition: 'all 0.2s ease-in-out',
                '& fieldset': {
                  borderColor: 'rgba(66, 133, 244, 0.3)',
                  borderWidth: '2px',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(66, 133, 244, 0.5)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgba(66, 133, 244, 0.8)',
                },
              },
              '& .MuiOutlinedInput-input': {
                '&::placeholder': {
                  color: 'rgba(0, 0, 0, 0.4)',
                  opacity: 1,
                },
              },
              '& .MuiFormHelperText-root': {
                fontSize: '0.9rem',
                marginLeft: '1.5rem',
                marginRight: '1.5rem',
              },
            }}
            placeholder={t('enterYourEmail') || undefined}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value);
            }}
            variant='outlined'
            color='primary'
            focused
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {email && (
              <Button
                variant='contained'
                size='large'
                color='googleBlue'
                disabled={!email || invalidEmail}
                sx={{
                  // margin: isExtraSmall ? '2rem 0rem' : '1.5rem 0rem',
                  maxWidth: '650px',
                  fontSize: aboveSmall ? '1.4rem' : isExtraSmall ? '1.2rem' : '1.3rem',
                  fontWeight: 700,
                  padding: '1rem 2rem',
                  borderRadius: '50px',
                  // justifyContent: 'center',
                  display: 'flex',
                  // alignItems: 'center',
                  textAlign: 'center',
                  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                  transition: 'box-shadow 0.3s ease-in-out',
                }}
                onClick={onContinue}
                endIcon={<ArrowForwardIcon />}
              >
                {t('continue')}
              </Button>
            )}
          </Box>
        </>
      </Stack>
    </Stack>
  );
}
export default EnterEmail;
