import React, { useEffect, useCallback } from 'react';

import {
  Button,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  Stack,
  IconButton,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectIsPlansDialogOpen,
  openChurnDialog,
  closePlansDialog,
  openAlreadyPaidDialog,
} from '../../features/ui/uiSlice';
import { grey } from '@mui/material/colors';
import AnalyticsService from '../../services/analytics/analyticsService';
import { EventName } from '../../services/analytics/providers/analyticsProvider';
import { BLACK_HEX, DARK_GRAY_HEX, WHITESMOKE_HEX } from '../../theme';
import UpsellBullet from './UpsellBullet';
import PlansContainer from './PlansContainer/PlansContainer';
import { useGetOffering } from '../../app/useGetOffering';
import { SHOW_BULK_DELETE_TAB } from '../../featureFlags';
import CloseIcon from '@mui/icons-material/Close';
import Mailbox from '../../model/mailbox';
import { useHasGivenFeedback } from '../../app/useGetChurnFeedback';
import {
  CurrencyConfig,
  PlanTypePresentable,
  PromoCode,
} from '../../features/offering/offeringSlice.types';
import { openCheckoutPage } from '../../utilities/paywallHelper';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export type PlansPopupProps = {
  mailbox: Mailbox | null | undefined;
};

function PlansPopup(props: PlansPopupProps) {
  const { mailbox } = props;

  const hasGivenFeedback = useHasGivenFeedback(mailbox);

  const isPlansDialogOpen = useAppSelector(selectIsPlansDialogOpen);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // const isChurnWinbackTestActive = churnWinbackTest?.test_value === A_GROUP;
  const useChurnWinback = true;

  const onDismiss = useCallback(() => {
    AnalyticsService.track(EventName.PLANS_DENY);

    if (useChurnWinback && !hasGivenFeedback) {
      dispatch(openChurnDialog());
    }

    dispatch(closePlansDialog());
  }, [hasGivenFeedback, dispatch, useChurnWinback]);

  const onAlreadyPaid = useCallback(() => {
    dispatch(openAlreadyPaidDialog());
    dispatch(closePlansDialog());
  }, [dispatch]);

  const theme = useTheme();
  const aboveSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const contentsFontSize = isExtraSmall ? '0.85rem' : aboveSmall ? '1rem' : '1rem';

  const useBullets = aboveSmall || true;

  const { hasLifetimeOption } = useGetOffering({ mailbox });

  const BULLETS: string[] = [
    'unlimitedUnsubscribes',
    ...(SHOW_BULK_DELETE_TAB ? ['unlimitedBulkDeletes'] : []),
    // 'neverSellData',
    'forAllInboxes',
    ...(aboveSmall ? ['prioritySupport'] : []),
    ...(!hasLifetimeOption ? ['cancelWhenever'] : []),
  ];

  useEffect(() => {
    if (isPlansDialogOpen) {
      AnalyticsService.track(EventName.PLANS_SHOW);
    }
  }, [isPlansDialogOpen]);

  const onCheckout = useCallback(
    async (
      plan: PlanTypePresentable,
      currency: CurrencyConfig,
      promoCode: PromoCode | undefined
    ) => {
      if (!plan || !currency) {
        return;
      }

      await openCheckoutPage({
        mailboxId: mailbox?.email_address,
        selectedPlan: plan.planType,
        currency,
        promoCode: promoCode?.id,
      });
    },
    [mailbox]
  );

  return (
    <Dialog
      open={!!isPlansDialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={onDismiss}
      aria-describedby='alert-dialog-slide-description'
      PaperProps={{
        sx: {
          backgroundColor: WHITESMOKE_HEX,
          ...(aboveSmall
            ? {
                borderRadius: '1rem',
                maxWidth: '800px',
              }
            : {
                maxWidth: 'none',
                margin: 0,
                width: '100%',
                alignSelf: 'end',
                borderRadius: '1rem 1rem 0rem 0rem',
                paddingTop: '2rem',
              }),
        },
      }}
    >
      {!aboveSmall && (
        <DialogTitle
          sx={{
            fontSize: isExtraSmall ? '1.25rem' : '1.5rem',
            fontWeight: 600,
            textAlign: 'center',
            lineHeight: 'normal',
            marginBottom: '1rem',
            ...(aboveSmall
              ? {}
              : {
                  padding: '8px 24px',
                }),
          }}
        >
          {t('updateToUnlimited')}
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          justifyItems: 'center',
          ...(aboveSmall ? { gap: '1rem', padding: '2rem' } : {}),
          paddingBottom: aboveSmall ? '0.5rem' : '1rem',
        }}
      >
        <Stack
          sx={{
            alignContent: 'center',
            display: 'flex',
            flexDirection: aboveSmall ? 'row' : 'column',
          }}
        >
          <Stack
            sx={{
              ...(aboveSmall
                ? { width: '50%', justifyContent: 'center' }
                : { marginBottom: '0rem' }),
            }}
          >
            {aboveSmall && (
              <Typography fontSize={'1.5rem'} fontWeight={700} color={BLACK_HEX}>
                {t('updateToUnlimited')}
              </Typography>
            )}
            {useBullets ? (
              BULLETS.map((bullet) => (
                <UpsellBullet key={bullet} text={t(bullet)} fontSize={contentsFontSize} />
              ))
            ) : (
              <Typography fontSize={contentsFontSize} color={DARK_GRAY_HEX} fontWeight='500'>
                {t('plansUpsellDescription')}
              </Typography>
            )}
          </Stack>
          <Stack
            sx={{
              ...(aboveSmall ? { width: '50%' } : {}),
            }}
          >
            <PlansContainer
              mailbox={mailbox}
              onAlreadyPaid={onAlreadyPaid}
              onCheckout={onCheckout}
            />
          </Stack>
        </Stack>
        {aboveSmall && (
          <Button
            variant='text'
            size='small'
            onClick={onDismiss}
            sx={{
              margin: aboveSmall ? '0.5rem auto' : isExtraSmall ? '0.5rem auto' : '1rem auto',
              fontSize: isExtraSmall ? '0.75rem' : '0.85rem',
              color: grey[700],
              width: 'fit-content',
            }}
          >
            {t('noThanks')}
          </Button>
        )}
      </DialogContent>

      {!aboveSmall && (
        <IconButton
          aria-label='close'
          size='small'
          sx={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}
          onClick={onDismiss}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Dialog>
  );
}
export default PlansPopup;
